import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";

import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";

import RelatedContent from "../../components/RelatedContent";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "Analytics Consulting": "/nl/analytics-consulting",
  "Analytics Advies": "/nl/analytics-consulting"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/analytics-consulting"
  },
  {
    hreflang: "de",
    href: "/de/google-analytics-beratung"
  },
  {
    hreflang: "se",
    href: "/se/analysrådgivning"
  },
  {
    hreflang: "da",
    href: "/da/analyse-konsulent"
  },
  {
    hreflang: "no",
    href: "/no/analyse-rådgivning"
  },
  {
    hreflang: "nl",
    href: "/nl/analytics-consulting"
  },
  {
    hreflang: "x-default",
    href: "/en/analytics-consulting"
  }
];

const AnalyticsConsulting = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Analytics Consulting als dienst voor Google Analytics & Co."
        description="Gecertificeerde analytics consultancydiensten online en op afstand voor webanalytics met Google Tag Manager, Google Analytics & Co."
        lang="nl"
        image="google-analytics-setup-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="google-analytics-setup/google-analytics-setup-hero.jpg"
          alt="man aan het werk met google analytics setup op laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Analytics Advies</H>
        <p>
          Bedrijven en ondernemers willen weten hoe ze hun digitale strategie kunnen optimaliseren.
          <br />
          Als ze beslissingen op basis van data konden nemen, zouden ze de risico's van investeren in de verkeerde kanalen kunnen verminderen.
        </p>
        <p>Maar om daar te komen, moeten eerst een paar dingen worden besloten.</p>
        <ul>
          <li>Wat moet er worden gevolgd? Wat moeten we weten?</li>
          <li>Hoe gaan we de data verzamelen?</li>
          <li>Hoe gaan we inzichten testen en verkeerde aannames opsporen?</li>
          <li>Hoe moet data worden gevisualiseerd voor de relevante teams?</li>
          <li>Wat gebeurt er na de eerste reeks wijzigingen? Gaan we onze aannames opnieuw bekijken?</li>
          <li>Hoe kunnen we een continue cyclus van meten, data-analyse, testen en implementatie introduceren?</li>
        </ul>
        <p>
          <strong>Analytics advies</strong> begeleidt je door deze reis. Van data <strong>verzameling</strong>,
          <strong>analyse</strong>, tot het nemen van <strong>data-gedreven beslissingen</strong>. Uiteindelijk zul je een
          continue <strong>optimalisatieproces</strong> voor je online bedrijf hebben.
        </p>
        <H as="h2">Wat is analytics advies?</H>
        <p>
          Analytics advies is een dienst die begeleiding biedt over alle webanalytics-disciplines. Van{" "}
          <Link to="/nl/google-analytics-installatie">het opzetten van Google Analytics</Link> met de juiste KPI's, tot het integreren van
          derde partij data. Het toegankelijk maken van de data voor teams door middel van datavisualisatie is een volgende stap. Het doel
          is om een continu proces van dataverzameling, analyse, implementatie en evaluatie te introduceren.
        </p>
        <H as="h4">Belangrijke gebieden van analytics adviesdiensten</H>
        <ul>
          <li>Planning van dataverzameling, analyse en optimalisatiecycli</li>
          <li>Selectie en implementatie van analytics tools</li>
          <li>Definitie van KPI's en metrics</li>
          <li>Meten van gebruikersgedrag</li>
          <li>Gebruikerssegmentatie</li>
          <li>Data-integratie met diensten van derden</li>
          <li>
            Datavisualisatie
          </li>
        </ul>
        <H as="h2">Webanalytics hulp voor elk type website</H>
        <p>Er is niet één analytics-oplossing die geschikt is voor alle websites.</p>
        <p>
          Als je website zich bijvoorbeeld alleen op content richt, zou je volgsysteem dat ook moeten doen. Een paar gebruikersgedrags- en contentmetrics zijn al genoeg om te zien waar bezoekers naar op zoek zijn.
        </p>
        <p>
          Op e-commerce websites is gebruikersgedrag ook belangrijk, maar meestal zijn er betere metrics beschikbaar.{" "}
          <br /> Een toevoeg-knop aan winkelwagen, bijvoorbeeld, duidt op meer interesse dan alleen een productweergave. Hetzelfde geldt
          voor inzoomen op een productafbeelding of het selecteren van kleurvarianten.
        </p>
        <p>
          Let op dat we in beide scenario's proberen te achterhalen hoe geïnteresseerd de bezoeker is in de content. Maar
          afhankelijk van het type website kiezen we verschillende metrics.
        </p>
        <p>
          E-commerce websites bieden meer functionaliteiten, dus we hebben meer indicatoren voor de interesse van de gebruiker.
          Tekstzware sites daarentegen, volgen bredere metrics, omdat er geen betere indicatoren zijn om te benutten.
        </p>
        <p>
          Het gebruik van specifieke indicatoren boven bredere, is erg belangrijk voor re-targeting, bijvoorbeeld. Op Facebook,
          Instagram en Google ads moet de doelgroep zo nauwkeurig mogelijk zijn, aangezien we per impressie betalen.{" "}
          <br /> Dus, afhankelijk van het type website, zijn er verschillende datapunten om te verzamelen.
        </p>
        <p>
          Advertentiekosten, bijvoorbeeld, worden beoordeeld op basis van conversies. Daarom is conversietracking
          van cruciaal belang voor
          e-commerce analytics.
        </p>
        <p>
          Gezondheidszorg- of financiële websites stellen veel vragen aan de bezoeker via formulieren. Ze gaan veel meer in detail dan andere websites. Dat komt omdat ze complexe producten hebben en dus meer moeten uitleggen. Ook zijn de opportuniteitskosten, gerelateerd aan een verkeerde productkeuze, veel hoger.
        </p>
        <p>
          Op dergelijke sites is het optimaliseren van de klantreis om bezoekers door de funnel te leiden, de belangrijkste focus. Aanvullende foutopsporing voor de formulierinzendingen zou een soepele ervaring garanderen.
        </p>
        <p>
          Zoals je kunt zien, zijn er veel verschillende soorten websites en afhankelijk van het bedrijfsmodel moeten verschillende KPI's worden gevolgd. Daarom houdt een <Link to="/nl/google-analytics-consultant">Google Analytics consultant</Link> rekening met al deze factoren en helpt je de juiste beslissingen te nemen voor je analytics setup.
        </p>
        <H as="h2">Voordelen van Google Analytics Advies</H>
        <p>
          Het voordeel van Google Analytics advies is de toegang tot hoogwaardige data. Het stelt je in staat om belangrijke zakelijke vragen te beantwoorden.
        </p>
        <p>
          Zodra je weet welke marketingkanalen en campagnes het beste converteren, weet je waar je je budget moet inzetten. Ook is het enorm behulpzaam om te weten welke categorieën, onderwerpen of kleurvarianten het beste presteren bij het toewijzen van middelen.
        </p>
        <p>
          Hulp van een <Link to="/nl/google-analytics-consultant">Google Analytics consultant</Link> richt zich op de juiste metrics en zorgt voor kwaliteit. Niet alleen dat, het zorgt ervoor dat je de onderneming om een "data-gedreven" bedrijf te worden, succesvol afrondt.
        </p>
        <p>
          Data en analytics op zichzelf veroorzaken niet direct winst. Analytics adviesbureaus benadrukken dit niet genoeg. <br /> Webanalytics biedt data om zakelijke vragen te beantwoorden en verkoopproblemen te ontdekken. Het <em>gebruik</em> van de data leidt uiteindelijk tot zakelijke winst. <br />
          Dus, een Google Analytics adviesdienst biedt je voordelen door antwoorden te vinden op je vragen.
        </p>
        <p>
          Evenzo geeft een autodashboard informatie over snelheid, oliedruk en motortemperatuur. Bij het rijden helpt het je te beslissen of je moet vertragen of als er iets mis is. Dit is een groot voordeel. Het dashboard is echter niet per se nodig om de auto te besturen. Het is mogelijk om zonder enige informatie te rijden en toch de bestemming te bereiken.
        </p>
        <p>
          Hetzelfde geldt voor analytics adviesdiensten. Ze zijn niet nodig als je gewoon een blog runt en wilt weten hoeveel bezoekers je per maand hebt.
          <br />
          Maar als je geld verdient met je website of er veel in investeert, is de kans groot dat je verkoop en kosten wilt optimaliseren.
          <br /> En daarbij helpen analytics adviesdiensten je precies daarmee.
        </p>
        {/* <RelatedContent /> */}
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsConsulting;
